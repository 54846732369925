import React, { useEffect, useRef } from "react";
import { Fade } from "react-reveal";
import CombinedComponent from "./invoicePreview/combinedComponent";
import { useMediaQuery } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getOneCartRequestActions } from "../../../store/cart/actions";
import { useDispatch } from "react-redux";
import Dashboard from "../../Dashboard";
import ReactToPrint from "react-to-print";

const SalesHistoryDetails = () => {
  const isTablet = useMediaQuery("(max-width: 960px)");
  const navigate = useNavigate();
  const { histId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    getOneCartRequestActions(histId)(dispatch);
  }, [dispatch, histId]);
  ;
  const componentRef = useRef();

  const handleBack = () => {
    if (location.pathname.includes("history/details")) {
      navigate('/sales/history')
    } else {
      navigate('/sale')
    }
  }
  return (
    <Dashboard>
      <Fade right>
        <div className="h-screen w-ful bg-opacity-30 backdrop-blur-sm lg:mt-0 fixed flex items-center justify-center z-10">
          <div className="bg-gray-50 w-screen shadow-2xl rounded-lg p-4 pb-8 h-screen mt-10 flex items-center justify-center mx-auto">
            <div className="row">
              <div className="col-md-12 ">
                <div className="tab-content ">
                  <div
                    className={`tab-pane fade active show overflow-y-auto ${isTablet ? "h-[80vh]" : "h-[89vh]"
                      } scrollbar-hide`}
                  >
                    <main
                      className={`${!isTablet && "px-5"
                        } xl:grid grid-cols-1 gap-10`}
                    >
                      <div
                        className={`bg-white ${!isTablet && "px-5"} rounded`}
                      >
                        <div className={`${!isTablet && "px-5"}`}>
                          <CombinedComponent ref={componentRef} />

                          <footer className="border-t-2 border-gray-300 py-3 mt-5 flex flex-col bg-white space-y-6">
                            <div className="flex row">
                              <div className="col-6 items-start justify-start">

                                <button className="bg-default hover:bg-default hover:text-white btn btn-default btn-s shadow-none" onClick={handleBack}>
                                  Back
                                </button>

                              </div>
                              <div className="col-6 text-right">
                                <ReactToPrint
                                  pageStyle={{ margin: "40px" }}

                                  trigger={() => (
                                    <button className="hover:bg-primaryHover hover:text-white btn  btn-s shadow-none" style={{ backgroundColor: "black" }} >
                                      Print Invoice
                                    </button>
                                  )}
                                  content={() => componentRef.current}
                                />
                              </div>
                            </div>
                          </footer>
                        </div>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Dashboard>
  );
};

export default SalesHistoryDetails;
