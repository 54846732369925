/* eslint-disable no-useless-concat */
import React from "react";
import { calculator, getActiveSubscriptionDetail } from "../../../../utils/helper";

const ClientSubscription = ({ dataToDisplay }) => {
  const subDetails = getActiveSubscriptionDetail(dataToDisplay?.subscription)
  return (
    <div className="row justify-center">
      <div className="col-xl-10 col-lg-12">
        <h2 className="text-black font-w600 ml-3 mt-2">Subscription</h2>

        <div id="" className="basic-form">
          <div className="card-body">
            <div
              className="custom-card card-border pt-4 pb-4 pl-4 pr-4 relative"
            >
              <div className="media items-list-2">
                <div className="media-body">
                  <h5 className="mt-0 mb-1 text-black">
                    {subDetails?.name}
                  </h5>
                  <ul className="fs-14 font-w500 list-inline">
                    <li>{`Consumption: ${subDetails?.usedAmount?.toLocaleString()}`}</li>
                  </ul>
                  <small className="text-primary font-w500 mb-3">
                    {`Balance: ${calculator(subDetails?.amount, subDetails?.usedAmount, "sub")?.toLocaleString()}`}
                  </small>
                  <span className="text-secondary mr-2 fo"></span>
                </div>
                <div className="media-footer align-self-center ml-auto d-block align-items-center">
                  <h3 className="mb-0 font-w600 text-secondary">
                    {`Rwf ${subDetails?.amount?.toLocaleString()}`}
                  </h3>
                </div>
              </div>
              <span
                className={`${subDetails?.isActive
                  ? "bg-green-500"
                  : "bg-red-500"
                  } border absolute right-2 text-white top-1 rounded border-gray-300 py-1 px-2 mb-0.5`}
              >
                {subDetails?.isActive ? "Active" : "Expired"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientSubscription;
