import axios from "axios";
import { SERVER_URL } from "../utils/constants";
//
// const BASE_URL = "https://14barber-backend.vercel.app/api/v1/";
const BASE_URL = SERVER_URL

export const axiosRequest = axios.create({
  baseURL: BASE_URL,
});

export default axios.create({
  baseURL: BASE_URL,
});

export const refreshPage = () => {
  window.location.reload(false);
};
