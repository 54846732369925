import React, { useEffect } from "react";
import { Button, Form, Input } from "antd";
import {
  createDiscountAction,
  updateDiscountActions,
} from "../../store/settings/actions";
import { useDispatch, useSelector } from "react-redux";

const DiscountForm = ({ onClose, dataToUpdate }) => {
  const { setting } = useSelector((state) => state);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    if (dataToUpdate) {
      const res = await updateDiscountActions(
        dataToUpdate?._id,
        values
      )(dispatch);
      if (res) {
        onClose();
        form.resetFields();
      }
    } else {
      const res = await createDiscountAction(values)(dispatch);
      if (res) {
        onClose();
        form.resetFields();
      }
    }
  };

  useEffect(() => {
    if (dataToUpdate) {
      form.setFieldsValue({ ...dataToUpdate });
    }
  }, [dataToUpdate, form]);

  return (
    <Form
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      style={{
        maxWidth: 600,
      }}
      layout="vertical"
    >
      <div className="flex items-center justify-center mb-4">
        <h2>{`${
          dataToUpdate ? `Update ${dataToUpdate?.name}` : "Add"
        } Discount`}</h2>
      </div>

      <Form.Item
        name="name"
        label="Name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input className="h-10" />
      </Form.Item>
      <small className="text-red-500">{setting?.errorMassage}</small>
      <Form.Item
        name="percentage"
        label="Percentage"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input className="h-10" type="number" />
      </Form.Item>
      <Form.Item name="descriprion" label="Terms & Conditions">
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item className="flex items-center justify-center">
        <Button
          type="primary"
          style={{ background: "black", color: "white" }}
          htmlType="submit"
          loading={setting?.isFetching || setting?.isUpdating}
          disabled={setting?.isFetching || setting?.isUpdating}
          className="w-80 h-10"
        >
          {`${dataToUpdate ? "Update" : "Submit"}`}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DiscountForm;
