/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Dashboard from "../../Dashboard";
import "../sales/index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMediaQuery } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import {
  createCartRequestAction,
  deleteCartRequestActions,
  getAllCartRequestAction,
} from "../../../store/cart/actions";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";
import DeleteIcon from "@mui/icons-material/Delete";
import { getUser } from "../../utils/common";
import { calculateTotalAmount } from "../../../utils/helper";
import ConfirmModel from "../../model/confirmModel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore } from '@fortawesome/free-solid-svg-icons';

const CartsDetails = () => {
  const { cart } = useSelector((state) => state);
  const isTablet = useMediaQuery("(max-width: 960px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [deleteModel, setDeleteModel] = useState(false);
  const [cartId, setCartId] = useState("");

  const user = getUser();
  const userId = user[0]?._id;

  const handleNewSale = async () => {
    const newCartId = await createCartRequestAction({ user: userId })(dispatch);
    navigate(`/sales/new/${newCartId}`);
  };

  useEffect(() => {
    getAllCartRequestAction(`?status=pending`)(dispatch);
  }, [dispatch]);

  const showDeleteModal = (value) => {
    setDeleteModel(true);
    setCartId(value);
  };

  const hideDeleteModal = () => {
    setDeleteModel(false);
    setCartId("");
  };

  const handleRemoveProduct = async () => {
    await deleteCartRequestActions(cartId, {
      isDeleted: true,
    })(dispatch);
    notification.success({ message: "Removed from the cart" });
    await getAllCartRequestAction("?status=pending")(dispatch);
    setCartId("");
    setDeleteModel(false);
  };

  return (
    <>
      <ToastContainer />
      <Dashboard>
        <div className={`content-body mt-[3rem] ${!isTablet && "ml-52"}`}>
          <div className="container-fluid">
            <div
              className={`relative form-head mb-3 flex items-center justify-between`}
            >
              {!isTablet && (
                <div className="mr-auto d-lg-block">
                  <h3 className="text-black font-w500 mb-6">Pending Cart</h3>

                </div>
              )}
              <div className="dropdown custom-dropdown">
                <button
                  type="button"
                  className="btn btn-sm btn-primary light d-flex align-items-center svg-btn shadow-none"
                  data-toggle="modal"
                  data-target="#new-client"
                  aria-expanded="false"
                  onClick={() => handleNewSale()}
                >
                  <i className="bi bi-plus-lg"></i>
                  <div className="text-left ml-3">
                    <span className="d-block fs-16">Add new Sale</span>
                  </div>
                </button>
              </div>
              {/* <button
                type="button"
                className="btn btn-sm btn-primary light d-flex align-items-center svg-btn shadow-none"
                data-toggle="modal"
                data-target="#new-client"
                aria-expanded="false"
                onClick={() => handleNewSale()}
              >
                <span className="fs-16 ">Add new Sell</span>
              </button> */}
            </div>

            <div className="flex flex-wrap flex-col gap-2 justify-start items-start py-2 rounded-lg">
              <div className="flex flex-wrap gap-4">

                {cart?.all?.data?.map((d, index) => (
                  <div
                    className={`relative ${isTablet ? "w-64" : "w-96"} h-fit p-4 bg-white text-black rounded-lg flex`} style={{ border: '1px solid #d1d1d1' }}
                  >
                    {/* Icon on the left */}
                    <div className="flex-none rounded-full bg-[#f8f8fb] p-2 flex items-center justify-center" style={{ width: '50px', height: '50px', }}>
                      <FontAwesomeIcon icon={faStore} className="text-black h-6 w-6" />
                    </div>

                    {/* Details on the right */}
                    <div className="flex-grow ml-3"> {/* Dynamic title */}
                      <p className="text-black fs-15">
                        <strong>Products:</strong> {d?.products?.length}
                      </p>
                      <p className="text-black fs-15">
                        <strong>Services:</strong> {d?.listOfService?.length}
                      </p>
                      <p className="text-black fs-15">
                        <strong>Created at:</strong> {d?.createdAt?.slice(0, 10)}
                      </p>
                      <p className="text-black fs-15">
                        <strong>Created by:</strong> {d?.user?.firstname ?? ""}
                      </p>
                      <p className="text-black fs-15">
                        <strong>Amount:</strong> {calculateTotalAmount(d ?? 0)?.toLocaleString()} RWF
                      </p>
                      <div className="flex justify-between items-center mt-3">
                        <span className="bg-[#f5f1da] text-[#e3b04b] text-xs font-semibold px-3 py-1 rounded">
                          {d?.status}
                        </span>
                        <Link to={`/sales/new/${d?._id}`}>
                          <div className="text-sm text-blue-600 hover:underline">
                            View Details
                          </div>
                        </Link>
                      </div>
                    </div>

                    {/* Delete button */}
                    <button
                      aria-label="delete"
                      className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
                      onClick={() => showDeleteModal(d?._id)}
                    >
                      <DeleteIcon fontSize="small" className="h-6 w-6" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
      <ConfirmModel
        open={deleteModel}
        hideModal={hideDeleteModal}
        name={"Cart"}
        onOk={handleRemoveProduct}
      />
    </>
  );
};

export default CartsDetails;
