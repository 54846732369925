import { Modal } from "antd";
import React from "react";

const CustomModel = ({ isOpen, onClose, contents, width, center }) => {
    return (
        <Modal
            open={isOpen}
            onCancel={onClose}
            width={width}
            footer={null}
            centered={center}
        >
            {contents}
        </Modal>
    );
};

export default CustomModel
