/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Dashboard from "../../Dashboard";
import { DatePicker, Spin } from "antd";
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import { getUser } from "../../utils/common";
import { reportDataResultAction } from "../../../store/cart/actions";
import { useDispatch, useSelector } from "react-redux";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { FaFileExport } from "react-icons/fa";
import './TableStyles.css';
import { amountPaidThoughPaymentMethod, calculator, dateFormatFunction, findBarberTotalAmount, findTotalAmount, findTotalProductAmount, getUniqueUsers } from "../../../utils/helper";

const { RangePicker } = DatePicker;

const Report = () => {
  const { cart } = useSelector((state) => state);
  const dispatch = useDispatch();
  const today = dayjs();

  const formattedDate = new Date().toISOString().slice(0, 10);
  const [dateRange, setDateRange] = useState([formattedDate, formattedDate]);

  const isTablet = useMediaQuery("(max-width: 960px)");
  const user = getUser();
  const findRole = user[0]?.roles[0]?.permissions;
  const permissions = [];
  findRole?.map((item) => permissions.push(item.permissions));

  function formatDate(dateString) {
    // Create a new Date object from the input string
    if (dateString === '') {
      return "No date seleted"

    } else {
      const date = new Date(dateString);

      // Define options for formatting the date
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: '2-digit'
      };

      // Use toLocaleDateString to format the date
      return date.toLocaleDateString('en-US', options);

    }

  }

  const handleRangeInputChange = (date, dateString) => {
    setDateRange(dateString)
  };

  const tableDate = formatDate(dateRange);
  const tableArray = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,];

  useEffect(() => {
    if (dateRange) {
      reportDataResultAction(`?startDate=${dateRange[0]}&endDate=${dateRange[1]}`)(dispatch)
    }
  }, [dispatch, dateRange])

  const resultFromBarder = cart?.reportData?.barber
  const resultFromProduct = cart?.reportData?.productDetails
  const resultFromService = cart?.reportData?.servicesDetails
  const resultFromPaymentMethod = cart?.reportData?.salesByPaymentMethod
  const resultFromExpense = cart?.reportData?.expenseDetails

  const morningDataToDisplay = resultFromService?.morning || tableArray
  const afternoonDataToDisplay = resultFromService?.afternoon || tableArray

  // Expense Calculation
  const totalMorningExpense = resultFromExpense?.result?.morningTotal?.totalCost
  const totalAfternoonExpense = resultFromExpense?.result?.afternoonTotal?.totalCost

  // Total Barber Calculation
  const totalMorningBarberAmount = findBarberTotalAmount(resultFromBarder?.morningData)
  const totalAfternoonBarberAmount = findBarberTotalAmount(resultFromBarder?.afternoonData)

  // Total Result Calculation
  const totalMorningAmount = resultFromBarder?.morningTotalAmountPaid
  const totalAfternoonAmount = resultFromBarder?.afternoonTotalAmountPaid

  // Service Calculation
  const totalMorningServiceAmount = findTotalAmount(morningDataToDisplay)?.totalAmount
  const totalAfternoonServiceAmount = findTotalAmount(afternoonDataToDisplay)?.totalAmount

  // product Calculation
  const totalMorningProductAmount = findTotalProductAmount(resultFromProduct?.morningData)
  const totalAfternoonProductAmount = findTotalProductAmount(resultFromProduct?.afternoonData)

  // Specific Column Total Calculation
  const totalMorningAmountPaid = calculator(calculator(totalMorningBarberAmount, totalMorningProductAmount, "add"), totalMorningServiceAmount, "add")
  const totalAfternoonAmountPaid = calculator(calculator(totalAfternoonBarberAmount, totalAfternoonProductAmount, "add"), totalAfternoonServiceAmount, "add")

  //paymentMethod Mrng Calculation 
  const totalMorningAmountFromMomo = amountPaidThoughPaymentMethod(resultFromPaymentMethod?.morning, "Momo")
  const totalMorningAmountFromCard = amountPaidThoughPaymentMethod(resultFromPaymentMethod?.morning, "Card")
  const totalMorningAmountFromCash = amountPaidThoughPaymentMethod(resultFromPaymentMethod?.morning, "Cash")
  const totalMorningAmountFromSubscription = amountPaidThoughPaymentMethod(resultFromPaymentMethod?.morning, "Subscription")

  //paymentMethod Afternoon Calculation 
  const totalAfternonnAmountFromMomo = amountPaidThoughPaymentMethod(resultFromPaymentMethod?.afternoon, "Momo")
  const totalAfternonnAmountFromCard = amountPaidThoughPaymentMethod(resultFromPaymentMethod?.afternoon, "Card")
  const totalAfternonnAmountFromCash = amountPaidThoughPaymentMethod(resultFromPaymentMethod?.afternoon, "Cash")
  const totalAfternonnAmountFromSubscription = amountPaidThoughPaymentMethod(resultFromPaymentMethod?.afternoon, "Subscription")

  // Balance Calculation
  const totalMorningBalanceAmount = calculator(totalMorningAmount, totalMorningExpense, "sub") || 0
  const totalAfternoonBalanceAmount = calculator(totalAfternoonAmount, totalAfternoonExpense, "sub") || 0
  const totalMoringCashBalance = calculator(totalMorningAmount, totalMorningAmountFromSubscription, "sub") || 0
  const totalAfternoonCashBalance = calculator(totalAfternoonAmount, totalAfternonnAmountFromSubscription, "sub") || 0

  // Grand, Subscription and Deposit Totals Calculation
  const grandTotal = calculator(totalMorningBalanceAmount, totalAfternoonBalanceAmount, "add") || 0
  const totalSubscriptionAmount = resultFromBarder?.subscriptionAmount || 0
  const totalDepositAmount = calculator(grandTotal, totalSubscriptionAmount, "add") || 0

  return (
    <Dashboard>
      <div className={`content-body  mt-[3rem] ${!isTablet && "ml-52"}`}>

        <div className="container-fluid">
          <div
            className={`relative form-head mb-3 flex items-center justify-between`}
          >
            {!isTablet && (
              <div className="mr-auto d-lg-block">
                <h2 className={`text-black font-w500 mb-6`}>Report</h2>
              </div>
            )}
          </div>

          <div className="mr-auto d-lg-block">
            <div className={`${!isTablet ? "flex items-center space-x-8" : "block space-y-2"} mb-4`}>
              <RangePicker
                allowClear={false}
                defaultValue={[today, today]}
                onChange={handleRangeInputChange}
                className="w-[30vh] py-2 px-2 rounded"
              />
            </div>
          </div>
          <div className="col-12">
            <div className="table-responsive">
              <div className="card">
                <div className="overflow-hidden">
                  {permissions.indexOf("export-product") !== -1 &&
                    !isTablet && (
                      <div className=" absolute right-6 items-center justify-center top-3 flex gap-2 mb-3">
                        <small>Export</small>
                        <ReactHTMLTableToExcel
                          id="test-table-xls-button"
                          className="btn btn-sm btn-primary light flex items-center svg-btn shadow-none z-15"
                          table="table-to-xls"
                          filename={`report ${tableDate} `}
                          sheet={tableDate}
                          buttonText={<FaFileExport />}
                        />
                      </div>
                    )}
                  <div className="card-body mt-10 relative">
                    {cart?.isReportFetching && <div className="absolute h-full w-full opacity-40 bg-black">
                      <div className="flex items-center justify-center mt-44">
                        <Spin tip="Loading" size="large" />
                      </div>
                    </div>}
                    <div className="table-responsive">
                      <table id="table-to-xls" className="display w-full">
                        <thead>
                          <tr>
                            <th rowSpan="2">DATE</th>
                            <th rowSpan="2">BARBER NAME</th>
                            <th rowSpan="2">ENTRY</th>
                            <th rowSpan="2">BARBER S</th>
                            <th colSpan="4">SALES</th>
                            <th colSpan="4">SERVICE</th>
                            <th colSpan="6">DAILY ENTRY</th>
                            <th colSpan="2">EXPENSES</th>
                            <th rowSpan="2">Cash Balance</th>
                            <th rowSpan="2">S. Balance</th>
                            <th rowSpan="2">Balance</th>
                            <th rowSpan="2">G. TOTAL</th>
                            <th rowSpan="2">S. TOTAL</th>
                            <th rowSpan="2">DEPOSIT</th>
                          </tr>
                          <tr>
                            <th>ITEM</th>
                            <th>QTY</th>
                            <th>RATE</th>
                            <th>AMOUNT</th>
                            <th>ITEM</th>
                            <th>QTY</th>
                            <th>RATE</th>
                            <th>AMOUNT</th>
                            <th>TOTAL</th>
                            <th>Disc(%)</th>
                            <th>MOMO</th>
                            <th>POS</th>
                            <th>Cash</th>
                            <th>Subscription</th>
                            <th>Description</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* Morning Section */}
                          <tr className="section-header">
                            <td rowSpan={parseInt((tableArray?.length + 3) * 2)}>{dateFormatFunction(dateRange[0], dateRange[1])}</td>
                            <td colSpan="2"></td>
                            <td rowSpan={parseInt((tableArray?.length + 1))}>
                              {totalMorningBarberAmount?.toLocaleString()}
                            </td>
                            <td colSpan="8">MORNING : {getUniqueUsers(resultFromBarder?.user?.morningUser)}</td>
                            <td rowSpan={parseInt((tableArray?.length + 2))}>
                              {totalMorningAmountPaid?.toLocaleString()}
                            </td>
                            <td colSpan="7"></td>
                            <td rowSpan={parseInt((tableArray?.length + 2))}>
                              {totalMoringCashBalance?.toLocaleString()}
                            </td>
                            <td rowSpan={parseInt((tableArray?.length + 2))}>
                              {totalMorningAmountFromSubscription?.toLocaleString()}
                            </td>
                            <td rowSpan={parseInt((tableArray?.length + 2))}>
                              {totalMorningBalanceAmount?.toLocaleString()}
                            </td>
                            <td rowSpan={parseInt((tableArray?.length + 3) * 2)} className="total-cell">{grandTotal}</td>
                            <td rowSpan={parseInt((tableArray?.length + 3) * 2)} className="total-cell">{totalSubscriptionAmount?.toLocaleString()}</td>
                            <td rowSpan={parseInt((tableArray?.length + 3) * 2)} className="total-cell">{totalDepositAmount?.toLocaleString()}</td>
                          </tr>
                          {
                            tableArray?.map((_service, index) => (

                              <tr className="morning">
                                <td>{resultFromBarder?.morningData[index]?.barberName}</td>
                                <td>{resultFromBarder?.morningData[index]?.totalEarnings}</td>

                                {/* Product */}
                                <td>{resultFromProduct?.morningData[index]?.productname}</td>
                                <td>{resultFromProduct?.morningData[index]?.quantity}</td>
                                <td>{resultFromProduct?.morningData[index]?.unitPrice}</td>
                                <td>{resultFromProduct?.morningData[index]?.totalAmount}</td>

                                {/* Service */}
                                <td>{resultFromService?.morning[index]?.serviceName}</td>
                                <td>{resultFromService?.morning[index]?.categoryName ? resultFromService?.morning[index]?.quantity : ""}</td>
                                <td>{resultFromService?.morning[index]?.unitPrice}</td>
                                <td>{resultFromService?.morning[index]?.totalAmount}</td>

                                <td>
                                  {resultFromPaymentMethod?.morning[index]?.discount ? resultFromPaymentMethod?.morning[index]?.discount : ""}
                                </td>
                                <td>
                                  {resultFromPaymentMethod?.morning[index]?.paymentMethod === "Momo" ? resultFromPaymentMethod?.morning[index]?.amoutPaid : ""}
                                </td>
                                <td>
                                  {resultFromPaymentMethod?.morning[index]?.paymentMethod === "Card" ? resultFromPaymentMethod?.morning[index]?.amoutPaid : ""}
                                </td>
                                <td>
                                  {resultFromPaymentMethod?.morning[index]?.paymentMethod === "Cash" ? resultFromPaymentMethod?.morning[index]?.amoutPaid : ""}
                                </td>
                                <td>
                                  {resultFromPaymentMethod?.morning[index]?.paymentMethod === "Subscription" ? resultFromPaymentMethod?.morning[index]?.amoutPaid : ""}
                                </td>
                                <td>{resultFromExpense?.morningData[index]?.name}</td>
                                <td>{resultFromExpense?.morningData[index]?.totalCost}</td>
                              </tr>
                            ))}
                          {/* More rows for the morning section */}
                          <tr>
                            <td className="observation" colSpan="2" rowSpan="2">Observation</td>
                            <td className="observation" colSpan="2" rowSpan="1"></td>

                            <td className="observation" colSpan="1">subtotal</td>
                            <td className="observation" colSpan="1"></td>
                            <td className="observation" colSpan="1">{totalMorningProductAmount?.toLocaleString()}</td>
                            <td className="observation" colSpan="2"></td>
                            <td className="observation" colSpan="1">subtotal</td>
                            <td className="observation" colSpan="1">{totalMorningServiceAmount?.toLocaleString()}</td>

                            <td className="observation" colSpan="1"></td>
                            <td className="observation" colSpan="1">{totalMorningAmountFromMomo?.toLocaleString()}</td>
                            <td className="observation" colSpan="1">{totalMorningAmountFromCard?.toLocaleString()}</td>
                            <td className="observation" colSpan="1">{totalMorningAmountFromCash?.toLocaleString()}</td>
                            <td className="observation" colSpan="1">{totalMorningAmountFromSubscription?.toLocaleString()}</td>
                            <td className="observation" colSpan="1">Total</td>
                            <td className="observation" colSpan="1">{totalMorningExpense?.toLocaleString()}</td>

                          </tr>

                          {/* Evening Section */}
                          <tr >
                            <td colSpan="12"></td>
                          </tr>
                          <tr className="section-header">
                            <td colSpan="2"></td>
                            <td rowSpan={parseInt((tableArray?.length + 1))}> {totalAfternoonBarberAmount?.toLocaleString()}</td>
                            <td colSpan="8">EVENING : {getUniqueUsers(resultFromBarder?.user?.afternoonUser)}</td>
                            <td rowSpan={parseInt((tableArray?.length + 2))}>{totalAfternoonAmountPaid?.toLocaleString()}</td>
                            <td colSpan="7"></td>
                            <td rowSpan={parseInt((tableArray?.length + 2))}>
                              {totalAfternoonCashBalance?.toLocaleString()}
                            </td>
                            <td rowSpan={parseInt((tableArray?.length + 2))}>
                              {totalAfternonnAmountFromSubscription?.toLocaleString()}
                            </td>
                            <td rowSpan={parseInt((tableArray?.length + 2))}>
                              {totalAfternoonBalanceAmount?.toLocaleString()}
                            </td>
                          </tr>
                          {
                            tableArray?.map((_service, index) => (
                              <tr className="evening">
                                <td>{resultFromBarder?.afternoonData[index]?.barberName}</td>
                                <td>{resultFromBarder?.afternoonData[index]?.totalEarnings}</td>

                                {/* Product */}
                                <td>{resultFromProduct?.afternoonData[index]?.productname}</td>
                                <td>{resultFromProduct?.afternoonData[index]?.quantity}</td>
                                <td>{resultFromProduct?.afternoonData[index]?.unitPrice}</td>
                                <td>{resultFromProduct?.afternoonData[index]?.totalAmount}</td>

                                {/* Services */}
                                <td>{resultFromService?.afternoon[index]?.serviceName}</td>
                                <td>{resultFromService?.afternoon[index]?.categoryName ? resultFromService?.afternoon[index]?.quantity : ""}</td>
                                <td>{resultFromService?.afternoon[index]?.unitPrice}</td>
                                <td>{resultFromService?.afternoon[index]?.totalAmount}</td>

                                <td>
                                  {resultFromPaymentMethod?.afternoon[index]?.discount ? resultFromPaymentMethod?.afternoon[index]?.discount : ""}
                                </td>
                                <td>
                                  {resultFromPaymentMethod?.afternoon[index]?.paymentMethod === "Momo" ? resultFromPaymentMethod?.afternoon[index]?.amoutPaid : ""}
                                </td>
                                <td>
                                  {resultFromPaymentMethod?.afternoon[index]?.paymentMethod === "Card" ? resultFromPaymentMethod?.afternoon[index]?.amoutPaid : ""}
                                </td>
                                <td>
                                  {resultFromPaymentMethod?.afternoon[index]?.paymentMethod === "Cash" ? resultFromPaymentMethod?.afternoon[index]?.amoutPaid : ""}
                                </td>
                                <td>
                                  {resultFromPaymentMethod?.afternoon[index]?.paymentMethod === "Subscription" ? resultFromPaymentMethod?.afternoon[index]?.amoutPaid : ""}
                                </td>
                                <td className="evening-expense">{resultFromExpense?.[index]?.name}</td>
                                <td>{resultFromExpense?.afternoonData[index]?.totalCost}</td>
                              </tr>
                            ))}
                          {/* More rows for the evening section */}
                          <tr>
                            <td className="observation" colSpan="2" rowSpan="2">Observation</td>
                            <td className="observation" colSpan="2" rowSpan="1"></td>

                            <td className="observation" colSpan="1">subtotal</td>
                            <td className="observation" colSpan="1"></td>
                            <td className="observation" colSpan="1">{totalAfternoonProductAmount?.toLocaleString()}</td>
                            <td className="observation" colSpan="2"></td>
                            <td className="observation" colSpan="1">subtotal</td>

                            <td className="observation" colSpan="1">{totalAfternoonServiceAmount?.toLocaleString()}</td>
                            <td className="observation" colSpan="1"></td>
                            <td className="observation" colSpan="1">{totalAfternonnAmountFromMomo?.toLocaleString()}</td>
                            <td className="observation" colSpan="1">{totalAfternonnAmountFromCard?.toLocaleString()}</td>
                            <td className="observation" colSpan="1">{totalAfternonnAmountFromCash?.toLocaleString()}</td>
                            <td className="observation" colSpan="1">{totalAfternonnAmountFromSubscription?.toLocaleString()}</td>
                            <td className="observation" colSpan="1">Total</td>
                            <td className="observation" colSpan="1">{totalAfternoonExpense?.toLocaleString()}</td>

                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default Report;
