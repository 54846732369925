import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "cleint",
  initialState: {
    isFetching: false,
    all: null,
    new: null,
    selected: null,
    clientWithSub: null,
    clientWithSubHistory: null,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setClientWithSub(state, action) {
      state.clientWithSub = action.payload;
    },
    setClientWithSubHistory(state, action) {
      state.clientWithSubHistory = action.payload;
    },
  },
});

export const myClienttActions = mySlice.actions;

export default mySlice.reducer;
