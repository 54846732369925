import React, { useEffect } from "react";
import { Button, Form, Input } from "antd";
import {
  createSubscriptionAction,
  updateSubscriptionActions,
} from "../../store/settings/actions";
import { useDispatch, useSelector } from "react-redux";

const SubscriptionForm = ({ onClose, dataToUpdate }) => {
  const { setting } = useSelector((state) => state);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    const payload = {
      ...values,
      sub_amount: [{ value: values?.amount }],
    };
    if (dataToUpdate) {
      const res = await updateSubscriptionActions(
        dataToUpdate?._id,
        values
      )(dispatch);
      if (res) {
        onClose();
        form.resetFields();
      }
    } else {
      const res = await createSubscriptionAction(payload)(dispatch);
      if (res) {
        onClose();
        form.resetFields();
      }
    }
  };

  useEffect(() => {
    if (dataToUpdate) {
      form.setFieldsValue({ ...dataToUpdate });
    }
  }, [dataToUpdate, form]);

  return (
    <Form
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      style={{
        maxWidth: 800,
      }}
      layout="vertical"
    >
      <div className="flex items-center justify-center mb-4">
        <h2>{`${
          dataToUpdate ? `Update ${dataToUpdate?.name}` : "Add"
        } Subscriptions`}</h2>
      </div>

      <div className="grid grid-cols-2 gap-x-2">
        <div>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input className="h-10" />
          </Form.Item>
          <small className="text-red-500">{setting?.errorMassage}</small>
        </div>
        {!dataToUpdate && (
          <Form.Item
            name="amount"
            label="Amount"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input className="h-10" type="number" />
          </Form.Item>
        )}
        <Form.Item
          name="sessions"
          label="Number of Sessions"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input className="h-10" type="number" />
        </Form.Item>
      </div>

      <Form.Item name="description" label="Terms & Conditions">
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item className="flex items-center justify-center">
        <Button
          type="primary"
          style={{ background: "black", color: "white" }}
          htmlType="submit"
          loading={setting?.isFetching || setting?.isUpdating}
          disabled={setting?.isFetching || setting?.isUpdating}
          className="w-80 h-10"
        >
          {`${dataToUpdate ? "Update" : "Submit"}`}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SubscriptionForm;
