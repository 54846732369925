import React, { useState } from "react";
import Dashboard from "../../Dashboard";
import { useMediaQuery } from "@mui/material";
import { getUser } from "../../utils/common";
import { Tabs } from "antd";
import { handleSeletSettingTab } from "../../../store/layout/actions";
import { useDispatch, useSelector } from "react-redux";
import DiscountTable from "../../tables/DiscountTable";
import SubscriptionTable from "../../tables/SubscriptionTable";
import CustomModel from "../../model/CustomModel";
import DiscountForm from "../../form/DiscountForm";
import SubscriptionForm from "../../form/SubscriptionForm";
import { mySettingActions } from "../../../store/settings";

const Management = () => {
  const { layout } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [isModelOpen, setIsModelOpne] = useState(false);

  const isTablet = useMediaQuery("(max-width: 960px)");
  const user = getUser();
  const findRole = user[0]?.roles[0]?.permissions;
  const permissions = [];
  findRole?.map((item) => permissions.push(item.permissions));

  const handleOpenModel = () => {
    setIsModelOpne(true);
    dispatch(mySettingActions.setErrorMassage(null));
  };

  const handleCloseModel = () => {
    setIsModelOpne(false);
  };

  const onChange = (tab) => {
    handleSeletSettingTab(tab)(dispatch);
  };

  const items = [
    {
      key: "discount",
      label: "Discount Management",
      children: <DiscountTable />,
    },
    {
      key: "subscription",
      label: "Subscription Management",
      children: <SubscriptionTable />,
    },
  ];

  return (
    <Dashboard>
      <div className={`content-body mt-[3rem] ${!isTablet && "ml-52"}`}>
        <div className="container-fluid">
          <div
            className={`relative form-head mb-3 flex items-center justify-between`}
          >
            <div className="mr-auto d-lg-block">
              <h3 className="text-black font-w500 mb-6">Settings</h3>
            </div>

            <div className="dropdown custom-dropdown">
              {permissions.indexOf("add-client") !== -1 && (
                <button
                  type="button"
                  className="btn btn-sm btn-primary light d-flex align-items-center svg-btn shadow-none"
                  data-toggle="modal"
                  data-target="#new-client"
                  aria-expanded="false"
                  onClick={handleOpenModel}
                >
                  <i className="bi bi-plus-lg"></i>
                  <div className="text-left ml-3">
                    <span className="fs-16 capitalize">{`Add ${layout?.currentSettingTab}`}</span>
                  </div>
                </button>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Tabs
                defaultActiveKey={layout?.currentSettingTab}
                items={items}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
      {layout?.currentSettingTab === "discount" ? (
        <CustomModel
          isOpen={isModelOpen}
          onClose={handleCloseModel}
          contents={<DiscountForm onClose={handleCloseModel} />}
        />
      ) : (
        <CustomModel
          width={800}
          isOpen={isModelOpen}
          onClose={handleCloseModel}
          contents={<SubscriptionForm onClose={handleCloseModel} />}
        />
      )}
    </Dashboard>
  );
};

export default Management;
