import React, { useEffect, useState } from "react";
import { getUser } from "../utils/common";
import { useMediaQuery } from "@mui/material";
import {
  deleteDiscountActions,
  getAllDiscountsAction,
  updateDiscountActions,
} from "../../store/settings/actions";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "antd";
import CustomModel from "../model/CustomModel";
import DiscountForm from "../form/DiscountForm";
import { mySettingActions } from "../../store/settings";
import ConfirmModel from "../model/confirmModel";

const DiscountTable = () => {
  const { setting } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [selectedSwitch, setSelectedSwitch] = useState(null);
  const [dataToUpdate, setDataToUpdate] = useState(null);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [openModel, setOpenModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);

  const isTablet = useMediaQuery("(max-width: 960px)");

  const user = getUser();
  const findRole = user[0]?.roles[0]?.permissions;
  const permissions = [];
  findRole?.map((item) => permissions.push(item.permissions));

  useEffect(() => {
    getAllDiscountsAction("?")(dispatch);
  }, [dispatch]);

  const onChange = async (checked, discId) => {
    setSelectedSwitch(discId);
    const res = await updateDiscountActions(discId, { isPublished: checked })(
      dispatch
    );
    if (res) {
      setSelectedSwitch(null);
    }
  };

  const handleOpenModel = (data) => {
    setOpenModel(true);
    setDataToUpdate(data);
    dispatch(mySettingActions.setErrorMassage(null));
  };

  const handleCloseModel = () => {
    setOpenModel(false);
    setDataToUpdate(null);
  };

  const handleOpenDeleteModel = (data) => {
    setOpenDeleteModel(true);
    setDataToDelete(data);
  };

  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
    setDataToDelete(null);
  };

  const handleDeleteDiscount = async () => {
    const res = await deleteDiscountActions(dataToDelete?._id)(dispatch);
    if (res) {
      handleCloseDeleteModel();
    }
  };

  return (
    <>
      <div className="table-responsive">
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <table id="table-to-xls" className="display table-hover w-full">
                <thead>
                  <tr className="border-b">
                    <th className="py-6">Name</th>
                    <th className="py-6">Percentage</th>
                    <th className="py-6">Active</th>
                    {permissions.indexOf("view-client") !== -1 && (
                      <th className="py-6">Action</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {setting?.all?.data?.map((item) => {
                    return (
                      <>
                        <tr key={item._id} className="border-b">
                          <td className="py-3 capitalize">{item?.name}</td>
                          <td className="py-3">{item?.percentage}</td>
                          {!isTablet && (
                            <td className="py-3 capitalize">
                              <Switch
                                checked={item?.isPublished}
                                onChange={(checked) =>
                                  onChange(checked, item?._id)
                                }
                                style={{
                                  backgroundColor: item?.isPublished
                                    ? "#eeb974"
                                    : "#757575",
                                  borderColor: "transparent",
                                }}
                                loading={
                                  setting?.isUpdating &&
                                  item?._id === selectedSwitch
                                }
                              />
                            </td>
                          )}
                          <td className="py-3">
                            {" "}
                            {permissions.indexOf("view-client") !== -1 && (
                              <div>
                                <button
                                  className="bg-gray-900 hover:bg-[#cf7500] rounded shadow btn-xs sharp mr-1 bell bell-link"
                                  data-toggle="modal"
                                  data-target="#new-supplier-modal"
                                  onClick={() => handleOpenModel(item)}
                                >
                                  <i className="fa fa-pencil text-white"></i>
                                </button>
                                <button
                                  className="btn btn-danger shadow-none btn-xs sharp"
                                  onClick={() => handleOpenDeleteModel(item)}
                                >
                                  <i className="fa fa-trash-o"></i>
                                </button>
                              </div>
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <CustomModel
        isOpen={openModel}
        onClose={handleCloseModel}
        contents={
          <DiscountForm
            onClose={handleCloseModel}
            dataToUpdate={dataToUpdate}
          />
        }
      />
      <ConfirmModel
        name={dataToDelete?.name}
        open={openDeleteModel}
        hideModal={handleCloseDeleteModel}
        onOk={handleDeleteDiscount}
        isLoading={setting?.isUpdating}
      />
    </>
  );
};

export default DiscountTable;
