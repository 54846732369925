import { LoadingButton } from "@mui/lab";
import { Button, Form, Input } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getActivePrice } from "../../utils/helper";
import { updateSubscriptionPriceActions } from "../../store/settings/actions";

const UpdatePriceForm = ({ priceToUpdate, onClose }) => {
  const { setting } = useSelector((state) => state);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    const payload = { sub_amount: [{ value: values?.value }] };
    const res = await updateSubscriptionPriceActions(
      priceToUpdate?._id,
      payload
    )(dispatch);
    if (res) {
      onClose();
      form.resetFields();
    }
  };

  useEffect(() => {
    form.setFieldsValue({ value: getActivePrice(priceToUpdate?.sub_amount) });
  }, [form, priceToUpdate]);

  return (
    <div className="relative">
      <Form
        form={form}
        name="register"
        onFinish={onFinish}
        style={{ maxWidth: "100%" }}
        scrollToFirstError
        layout="vertical"
      >
        <Form.Item
          name="value"
          label="Amount"
          rules={[
            {
              required: true,
              message: "Price is required!",
              whitespace: true,
            },
          ]}
        >
          <Input type="number" className="h-10" />
        </Form.Item>

        <div className="flex items-center space-x-2">
          <Form.Item>
            <LoadingButton
              variant="outlined"
              sx={{ minWidth: "100%" }}
              disabled={setting?.isFetching || setting?.isUpdating}
              onClick={() => onClose()}
            >
              {"Cancel"}
            </LoadingButton>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              style={{ background: "black", color: "white" }}
              htmlType="submit"
              loading={setting?.isFetching || setting?.isUpdating}
              disabled={setting?.isFetching || setting?.isUpdating}
              className="w-24 h-10"
            >
              Update
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default UpdatePriceForm;
