import React from "react";

import { Modal, Button, Typography } from "antd";
import "../../assets/styles/modal.css";
const { Text } = Typography;

const ConfirmModel = ({
  open,
  onOk,
  hideModal,
  name,
  isLoading,
}) => {
  return (
    <Modal
      className="delete-modal"
      open={open}
      title={
        <svg
          viewBox="0 0 1024 1024"
          fill="currentColor"
          color="red"
          height="2em"
          width="2em"
        >
          <path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z" />
          <path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
        </svg>
      }
      onCancel={hideModal}
      footer={[
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Button
            key="back"
            onClick={hideModal}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            danger
            onClick={onOk}
            className="ml-4"
            loading={isLoading}

          >
            Remove
          </Button>
        </div>,
      ]}
    >
      <Text
        style={{
          fontSize: "20px",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        Do you really want to remove {name}?
      </Text>
    </Modal>
  );
};

export default ConfirmModel;

