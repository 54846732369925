import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "settings",
  initialState: {
    isFetching: false,
    isUpdating: false,
    all: null,
    new: null,
    allSubscrition: null,
    newSubscription: null,
    errorMassage: null,
    allSubManagement: null
  },
  reducers: {
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setIsUpdating(state, action) {
      state.isUpdating = action.payload;
    },
    setAll(state, action) {
      state.all = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setAllSubscrition(state, action) {
      state.allSubscrition = action.payload;
    },
    setNewSubscription(state, action) {
      state.newSubscription = action.payload;
    },
    setErrorMassage(state, action) {
      state.errorMassage = action.payload;
    },
    setAllSubManagement(state, action) {
      state.allSubManagement = action.payload;
    },
  },
});

export const mySettingActions = mySlice.actions;

export default mySlice.reducer;
