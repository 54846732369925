import moment from "moment";

export const calculateTotalAmount = (data, clientServices = []) => {
  let totalAmount = 0;
  // if (data.listOfService) {
  //   for (const service of data.listOfService) {
  //     totalAmount += parseFloat(service.amount);
  //   }
  // }
  if (data.listOfService) {
    for (const service of data.listOfService) {
      // Skip services if their IDs exist in clientServices
      if (!clientServices.includes(service._id)) {
        totalAmount += parseFloat(service.amount);
      }
    }
  }

  if (data.products) {
    for (const product of data.products) {
      const productCost =
        parseFloat(product.productId.amount) * parseInt(product.quantity, 10);
      totalAmount += productCost;
    }
  }

  return totalAmount;
};

export const reformatData = (data) => {
  const monthMap = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };
  const reformattedData = data?.map((item) => {
    const dateParts = item?.date?.split("-");
    const month = monthMap[dateParts[1]];
    return {
      date: `${month}`,
      overallTotal: item?.overallTotal,
    };
  });

  return reformattedData;
};

export const calculateTotalProductCost = (data) => {
  let totalCost = 0;
  data?.forEach((item) => {
    if (item.products && item.products.length > 0) {
      item.products?.forEach((product) => {
        const quantity = product.quantity;
        const amount = parseFloat(product?.productId?.amount);
        if (!isNaN(amount)) {
          totalCost += quantity * amount;
        }
      });
    }
  });

  return totalCost;
};

export const calculateTotalServiceCost = (data) => {
  let totalCost = 0;

  data?.forEach((item) => {
    if (item.listOfService && item.listOfService.length > 0) {
      item.listOfService?.forEach((service) => {
        const cost = parseFloat(service.amount);
        if (!isNaN(cost)) {
          totalCost += cost;
        }
      });
    }
  });

  return totalCost;
};

export const findDataById = (id, data) => {
  if (!Array.isArray(data)) {
    return null;
  }

  const result = data.find(item => item._id === id);

  return result || null;
};

export const calculateDueDate = (startDate, numberOfDays) => {

  const date = new Date(startDate);
  if (isNaN(date)) {
    return { error: "Invalid start date. Please provide a valid date." };
  }

  if (typeof numberOfDays !== "number" || numberOfDays < 0) {
    return { error: "Invalid number of days. Please provide a positive number." };
  }

  date.setDate(date.getDate() + numberOfDays);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const getActivePrice = (amount) => {
  return amount?.find((price) => price?.isActive)?.value || 0;
};


export const getUniqueUsers = (data) => {
  if (!Array.isArray(data) || data?.length === 0) {
    return "";
  }
  return data?.map((el) => el?.name)?.join(", ");
}

export const findTotalAmount = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    return { totalAmount: 0, totalAmountPaid: 0 };
  }

  return data.reduce(
    (totals, item) => {
      totals.totalAmount += Number(item.totalAmount) || 0;
      totals.totalAmountPaid += Number(item.serviceAmount) || 0;
      return totals;
    },
    { totalAmount: 0, totalAmountPaid: 0 }
  );
}

export const calculator = (num1, num2, operation) => {
  switch (operation) {
    case "add":
      return num1 + num2;
    case "sub":
      return num1 - num2;
    case "mul":
      return num1 * num2;
    case "div":
      return num2 !== 0 ? num1 / num2 : "Cannot divide by zero";
    default:
      return "Invalid operation";
  }
};

export const amountPaidThoughPaymentMethod = (data, paymentMethod) => {
  if (!Array.isArray(data) || data.length === 0) {
    return 0;
  }

  return data
    .filter(item => item.paymentMethod === paymentMethod)
    .reduce((total, item) => total + (Number(item.amoutPaid) || 0), 0);
}

export const findTotalProductAmount = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    return 0;
  }
  return data.reduce((total, item) => total + (Number(item.totalAmount) || 0), 0);
}

export const findBarberTotalAmount = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    return 0;
  }
  return data.reduce((total, item) => total + (Number(item.totalEarnings) || 0), 0);
}

export const dateFormatFunction = (date1, date2) => {
  const formatString = "Do, MMM YY";

  const formattedDate1 = moment(date1).format(formatString);
  const formattedDate2 = moment(date2).format(formatString);

  if (moment(date1).isSame(date2, 'day')) {
    return formattedDate1;
  } else {
    return `${formattedDate1} - ${formattedDate2}`;
  }
}

export const getActiveSubscriptionDetail = (data) => {
  if (!data || typeof data !== "object" || !Array.isArray(data.subscriptionDetails)) {
    return null;
  }

  return data.subscriptionDetails.find(detail => detail?.isActive) || null;
};
