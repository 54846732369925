import { useDispatch, useSelector } from "react-redux";
import { addToCartRequestActions } from "../../store/cart/actions";
import { useParams } from "react-router-dom";
import { getAllSubscriptionsAction } from "../../store/settings/actions";
import { Spin } from "antd";
import { useState } from "react";

const MembershipCard = ({ name, sessions, day, price, data }) => {
  const { cart } = useSelector((state) => state);
  const [subId, setSubId] = useState(null);
  const dispatch = useDispatch();
  const { cartId } = useParams();

  const handleSelectedMambership = async (data) => {
    setSubId(data?._id);
    const res = await addToCartRequestActions(cartId, {
      subscription: data?._id,
      hasSubscription: true,
      subscriptionAmoutPaid: price,
    })(dispatch);
    if (res) {
      setSubId(null);
      await getAllSubscriptionsAction("?isPublished=true")(dispatch);
    }
  };

  return (
    <div
      className={`border rounded-lg px-4 py-10 shadow-sm relative ${
        cart?.selected?.data?.Subscription?._id === data?._id
          ? "bg-yellow-100"
          : ""
      }`}
      onClick={() => handleSelectedMambership(data)}
    >
      {cart?.isUpdating && subId === data?._id && (
        <div className="bg-black absolute h-full w-full top-0 left-0 opacity-40">
          <div className="flex items-center justify-center h-full">
            <Spin tip="Loading" size="large" />
          </div>
        </div>
      )}
      <h2 className="font-medium text-xl text-gray-800 mb-2">{name}</h2>
      <div className="text-gray-700 text-sm space-x-2">
        <span>{sessions} Sessions</span>
        <span>&bull;</span>
        <span>{day} Days</span>
        <span>&bull;</span>
        <span className="font-medium">RWF {price?.toLocaleString()}</span>
      </div>
    </div>
  );
};

export default MembershipCard;
