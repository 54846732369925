import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "layout",
  initialState: {
    currentSettingTab: "discount",
  },
  reducers: {
    setCurrentSettingTab(state, action) {
      state.currentSettingTab = action.payload;
    },
  },
});

export const myLayoutActions = mySlice.actions;

export default mySlice.reducer;
