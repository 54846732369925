import React, { useEffect, useState } from "react";
import { getUser } from "../utils/common";
import { useMediaQuery } from "@mui/material";
import {
  deleteDiscountActions,
  deleteSubscriptionActions,
  getAllSubscriptionsAction,
  updateSubscriptionActions,
} from "../../store/settings/actions";
import { useDispatch, useSelector } from "react-redux";
import { Popover, Switch } from "antd";
import CustomModel from "../model/CustomModel";
import SubscriptionForm from "../form/SubscriptionForm";
import { mySettingActions } from "../../store/settings";
import { getActivePrice } from "../../utils/helper";
import { BiSolidEdit } from "react-icons/bi";
import UpdatePriceForm from "../form/UpdatePriceForm";
import ConfirmModel from "../model/confirmModel";

const SubscriptionTable = () => {
  const { setting } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [selectedSwitch, setSelectedSwitch] = useState(null);
  const [dataToUpdate, setDataToUpdate] = useState(null);
  const [priceToUpdate, setPriceToUpdate] = useState(null);
  const [openModel, setOpenModel] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [dataToDelete, setDataToDelete] = useState(null);

  const isTablet = useMediaQuery("(max-width: 960px)");

  const user = getUser();
  const findRole = user[0]?.roles[0]?.permissions;
  const permissions = [];
  findRole?.map((item) => permissions.push(item.permissions));

  useEffect(() => {
    getAllSubscriptionsAction("?")(dispatch);
  }, [dispatch]);

  const onChange = async (checked, subscrId) => {
    setSelectedSwitch(subscrId);
    const res = await updateSubscriptionActions(subscrId, {
      isPublished: checked,
    })(dispatch);
    if (res) {
      setSelectedSwitch(null);
    }
  };

  const handleOpenModel = (data) => {
    setOpenModel(true);
    setDataToUpdate(data);
    dispatch(mySettingActions.setErrorMassage(null));
  };

  const handleCloseModel = () => {
    setOpenModel(false);
    setDataToUpdate(null);
  };

  const hanldeClosePopover = () => {
    setOpen(false);
    setPriceToUpdate(null);
  };
  const handleOpenChange = (open, data) => {
    setOpen(open);
    setPriceToUpdate(data);
  };

  const handleOpenDeleteModel = (data) => {
    setOpenDeleteModel(true);
    setDataToDelete(data);
  };

  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
    setDataToDelete(null);
  };

  const handleDeleteSub = async () => {
    const res = await deleteSubscriptionActions(dataToDelete?._id)(dispatch);
    if (res) {
      handleCloseDeleteModel();
    }
  };

  return (
    <>
      <div className="table-responsive">
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <table id="table-to-xls" className="display table-hover w-full">
                <thead>
                  <tr className="border-b">
                    <th className="py-6">Name</th>
                    <th className="py-6">Amount (RWF)</th>
                    <th className="py-6">No Sessions</th>
                    <th className="py-6">Active</th>
                    {permissions.indexOf("view-client") !== -1 && (
                      <th className="py-6">Action</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {setting?.allSubscrition?.data?.map((item) => {
                    return (
                      <>
                        <tr key={item._id} className="border-b">
                          <td className="py-3 capitalize">{item?.name}</td>
                          <td className="py-3">
                            <div className="flex items-center gap-x-5 mx-auto justify-center">
                              {getActivePrice(
                                item?.sub_amount
                              )?.toLocaleString()}
                              <Popover
                                content={
                                  <UpdatePriceForm
                                    onClose={hanldeClosePopover}
                                    priceToUpdate={priceToUpdate}
                                  />
                                }
                                trigger="click"
                                open={priceToUpdate?._id === item._id}
                                onOpenChange={(open) =>
                                  handleOpenChange(open, item)
                                }
                              >
                                <BiSolidEdit size={20} />
                              </Popover>
                            </div>
                          </td>
                          <td className="py-3">{item?.sessions}</td>
                          {!isTablet && (
                            <td className="py-3 capitalize">
                              <Switch
                                checked={item?.isPublished}
                                onChange={(checked) =>
                                  onChange(checked, item?._id)
                                }
                                style={{
                                  backgroundColor: item?.isPublished
                                    ? "#eeb974"
                                    : "#757575",
                                  borderColor: "transparent",
                                }}
                                loading={
                                  setting?.isUpdating &&
                                  item?._id === selectedSwitch
                                }
                              />
                            </td>
                          )}
                          <td className="py-3">
                            {" "}
                            {permissions.indexOf("view-client") !== -1 && (
                              <div>
                                <button
                                  className="bg-gray-900 hover:bg-[#cf7500] rounded shadow btn-xs sharp mr-1 bell bell-link"
                                  data-toggle="modal"
                                  data-target="#new-supplier-modal"
                                  onClick={() => handleOpenModel(item)}
                                >
                                  <i className="fa fa-pencil text-white"></i>
                                </button>
                                <button
                                  className="btn btn-danger shadow-none btn-xs sharp"
                                  onClick={() => handleOpenDeleteModel(item)}
                                >
                                  <i className="fa fa-trash-o"></i>
                                </button>
                              </div>
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <CustomModel
        isOpen={openModel}
        onClose={handleCloseModel}
        contents={
          <SubscriptionForm
            onClose={handleCloseModel}
            dataToUpdate={dataToUpdate}
          />
        }
      />
      <ConfirmModel
        name={dataToDelete?.name}
        open={openDeleteModel}
        hideModal={handleCloseDeleteModel}
        onOk={handleDeleteSub}
        isLoading={setting?.isUpdating}
      />
    </>
  );
};

export default SubscriptionTable;
